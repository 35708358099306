/* Contenedor general */
.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    font-family: Arial, sans-serif;
    max-width: 300px;
    margin: 20px auto;
}

/* Etiqueta del input */
.calendar-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

/* Estilo del input */
.calendar-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #333;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Efecto al pasar el mouse */
.calendar-input:hover {
    border-color: #007BFF;
}

/* Efecto al enfocar el input */
.calendar-input:focus {
    border-color: #007BFF;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}